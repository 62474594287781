import "./App.css";
import { API } from "@stoplight/elements";
import "@stoplight/elements/styles.min.css";

function App() {
  return (
    <div className="App">
      <API
        router="hash"
        hideInternal={true}
        apiDescriptionUrl="https://public-api-docs.breshna.io/"
        hideSchemas={true}
        layout="responsive"
      />
    </div>
  );
}

export default App;
